import React from "react";
import * as styles from "./Nav.module.scss";
const Nav = ({ logo }) => {
  return (
    <nav className={styles.nav}>
      <div>
        {logo.length > 0 && (
          <>
            <div className={styles.logo}>
              <img src={logo} />
            </div>
            <div className={styles.line} />
          </>
        )}
        Merkevarehåndbok
      </div>
    </nav>
  );
};

export default Nav;
