import React from "react";
import PropTypes from "prop-types";
import * as styles from "./FileUpload.module.scss";
import Icon from "../Icon/Icon";

const FileUpload = ({ node: { title, file } }) => {
  if (!file && !title) return null;
  let { url = "", extension = "" } = file.asset;

  return url ? (
    <a
      icon="file"
      href={`${url}?dl=${title}.${extension}`}
      className={styles.file}
    >
      <span className={styles.buttonContent}>
        {title}.{extension}
      </span>
      <span className={styles.downloadIcon}>
        <Icon icon="download" />
      </span>
    </a>
  ) : (
    ""
  );
};

FileUpload.propTypes = {
  node: PropTypes.shape({
    title: PropTypes.string.isRequired,
    file: PropTypes.object.isRequired,
  }),
};

export default FileUpload;
