import React, { useContext } from "react";
import { GlobalStateContext } from "../../context/globalContext";
import * as styles from "./Footer.module.scss";
import { toDateString } from "../../lib/helpers";
const Footer = () => {
  const { updatedAt } = useContext(GlobalStateContext);
  return (
    <footer className={styles.footer}>
      <span>Sist oppdatert: {toDateString(updatedAt)}</span>
    </footer>
  );
};

export default Footer;
