import Color from "color";

export const WcagContrast = (color1, color2) =>
  Color(color1).contrast(Color(color2)).toFixed(1);

export const cleanHex = (colorString) =>
  colorString.startsWith("#") ? colorString : "#" + colorString;

export const getColorValues = (color, colorType) => {
  color = Color(color)[colorType]().color;
  return color.map((value) => Math.round(value));
};

export const toRGB = (color) => {
  const [r, g, b] = getColorValues(color, "rgb");
  return `RGB: ${r} ${g} ${b}`;
};

export const toHSL = (color) => {
  const [h, s, l] = getColorValues(color, "hsl");
  return `HSL: ${h}° ${s}% ${l}%`;
};

export const toCMYK = (color) => {
  const [c, m, y, k] = getColorValues(color, "cmyk");
  return `CMYK: ${c} ${m} ${y} ${k}`;
};

export const whiteHasHighestContrast = (color) => {
  const whiteTextContrast = Color("#edeff0").contrast(Color(color));
  const blackTextContrast = Color("#212328").contrast(Color(color));
  return whiteTextContrast > blackTextContrast;
};
