import React from "react";
import { cleanHex, WcagContrast } from "../ColorPreview/colorUtils";
import WCAGLabel from "../WCAGLabel/WCAGLabel";
import * as styles from "./ColorCombination.module.scss";

const ColorCombinations = ({ node: { colorCombinations } }) => {
  colorCombinations = colorCombinations.map(({ color1, color2 }) => ({
    color1: cleanHex(color1),
    color2: cleanHex(color2),
  }));
  return (
    <div
      className={styles.combinations}
      style={{
        gridTemplateColumns: `repeat(${colorCombinations.length}, 1fr)`,
      }}
    >
      {colorCombinations.map(({ color1, color2 }) => (
        <div
          key={color1 + color2}
          style={{
            backgroundColor: color2,
          }}
          className={styles.outerColor}
        >
          <div
            style={{ backgroundColor: color1 }}
            className={styles.innerColor}
          />
          <div className={styles.overlay}>
            {WcagContrast(color1, color2)}
            <WCAGLabel conrastRatio={WcagContrast(color1, color2)} largeText />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ColorCombinations;
