import React from "react";
import Icon from "../Icon/Icon";
import * as styles from "./TextStyles.module.scss";

const Text = ({
  name,
  fontLetterSpacing,
  fontLineHeight,
  fontSize,
  fontWeight,
  ligatures,
  fontFamily,
}) => {
  const Tag = ({ children, style, className }) => {
    switch (name) {
      case "Heading 1":
        return (
          <h1 style={style} className={className}>
            {children}
          </h1>
        );
      case "Heading 2":
        return (
          <h2 style={style} className={className}>
            {children}
          </h2>
        );
      case "Heading 3":
        return (
          <h3 style={style} className={className}>
            {children}
          </h3>
        );
      case "Heading 4":
        return (
          <h4 style={style} className={className}>
            {children}
          </h4>
        );
      case "Heading 5":
        return (
          <h5 style={style} className={className}>
            {children}
          </h5>
        );
      case "Heading 6":
        return (
          <h6 style={style} className={className}>
            {children}
          </h6>
        );
      case "Subtitle 1":
        return (
          <h5 style={style} className={className}>
            {children}
          </h5>
        );
      case "Subtitle 2":
        return (
          <h6 style={style} className={className}>
            {children}
          </h6>
        );
      case "Body 1":
        return (
          <p style={style} className={className}>
            {children}
          </p>
        );
      case "Body 2":
        return (
          <p style={style} className={className}>
            {children}
          </p>
        );
      case "Button":
        return (
          <p style={style} className={className}>
            {children}
          </p>
        );
      case "Overline":
        return (
          <p style={style} className={className}>
            {children}
          </p>
        );
      case "Caption":
        return (
          <p style={style} className={className}>
            {children}
          </p>
        );
      default:
        <p>{children}</p>;
    }
  };
  const numberOfLines = fontSize < 24 ? 4 : 2;
  return (
    <div className={styles.wrapper}>
      <div className={styles.textInfo}>
        <h2>{name}</h2>
        <div className={styles.textValues}>
          <div>
            <Icon icon="fontSize" />
            {fontSize}px
          </div>
          <div>
            <Icon icon="lineHeight" />
            {fontLineHeight}%
          </div>
          {fontLetterSpacing > 0 && (
            <div>
              <Icon icon="letterSpacing" />
              {fontLetterSpacing}%
            </div>
          )}

          <div>
            <Icon icon="textStyle" />
            {fontWeight}
          </div>
          {ligatures && (
            <div>
              <Icon icon="ligatures" />
              With ligatures
            </div>
          )}
        </div>
      </div>
      <Tag
        style={{
          fontSize,
          letterSpacing: fontLetterSpacing,
          lineHeight: `${fontLineHeight}%`,
          fontWeight,
          ligatures,
          fontFamily,
          fontWeight,
          maxHeight: ((fontSize * fontLineHeight) / 100) * numberOfLines,
        }}
        className={styles.text}
      >
        Far far away, behind the word mountains, far from the countries Vokalia
        and Consonantia, there live the blind texts. Separated they live in
        Bookmarksgrove right at the coast of the Semantics, a large language
        ocean. A small river named Duden flows by their place and supplies it
        with the necessary regelialia. It is a paradisematic country, in which
        roasted parts of sentences fly into your mouth. Even the all-powerful
        Pointing has no control about the blind texts it is an almost
        unorthographic life One day however a small line of blind text by the
        name
      </Tag>
    </div>
  );
};

const TextStyles = ({ node: { textStyles } }) => {
  return (
    <div>
      {textStyles.map((textStyle, index) => (
        <Text {...textStyle} key={"1: " + textStyle.name} />
      ))}
    </div>
  );
};

export default TextStyles;
