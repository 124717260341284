import React from "react";
import { getImageResolution } from "../../lib/helpers";
import Image from "../Image/Image";
import * as styles from "./ImageGrid.module.scss";
const ImageGrid = ({ node: { images } }) => {
  return (
    <div
      className={styles.gridWrapper}
      style={{ gridTemplateColumns: `repeat(${images.length}, 1fr)` }}
    >
      {images.map((image, index) => (
        <Image
          image={image}
          width={getImageResolution(images.length)}
          key={`image-grid-${index}`}
        />
      ))}
    </div>
  );
};

export default ImageGrid;
