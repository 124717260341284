import React from "react";
import iconlibrary from "./iconlibrary";

const Icon = ({ icon, size = 24 }) => {
  const getIcon = (icon) => (iconlibrary[icon] ? iconlibrary[icon] : null);
  return (
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {getIcon(icon)}
    </svg>
  );
};

export default Icon;
