import React from "react";
import { urlFor } from "../../lib/sanityClient";
import { image as imageStyle } from "./Image.module.scss";

const Image = ({ node, image, width }) => {
  image = node || image;
  if (image && image.asset.extension === "svg") {
    return <img className={imageStyle} src={image.asset.url} />;
  } else {
    return (
      <img className={imageStyle} src={urlFor(image).width(width).url()} />
    );
  }
};

Image.defaultProps = {
  width: 2000,
};

export default Image;
