import { Link } from "gatsby";
import React, { useContext } from "react";
import { GlobalStateContext } from "../../context/globalContext";
import { cn, slugify } from "../../lib/helpers";
import * as styles from "./SideNav.module.scss";

const SideNav = ({ subPages }) => {
  return (
    <nav className={styles.wrapper}>
      <ul>
        {subPages.map(({ title }) => (
          <li key={"sidenav-" + title}>
            <Link
              activeClassName={cn(styles.link, styles.active)}
              className={styles.link}
              to={`/${slugify(title)}`}
            >
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SideNav;
