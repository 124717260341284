import React from "react";
import { getImageResolution } from "../../lib/helpers";
import BlockContent from "../BlockContent/BlockContent";
import Image from "../Image/Image";
import * as styles from "./ImageAndTextGrid.module.scss";

const ImageAndTextGrid = ({ node: { list } }) => {
  return (
    <div
      className={styles.gridWrapper}
      style={{ gridTemplateColumns: `repeat(${list.length}, 1fr)` }}
    >
      {list.map(({ image, text }, index) => (
        <div key={index + image.asset._ref}>
          <Image image={image} width={getImageResolution(list.length)} />
          <BlockContent blocks={text} />
        </div>
      ))}
    </div>
  );
};

export default ImageAndTextGrid;
