import React, { useState } from "react";

const GlobalStateContext = React.createContext();

const GlobalStateContextProvider = ({ children }) => {
  const [updatedAt, setUpdatedAt] = useState("");
  const [logo, setLogo] = useState([]);
  return (
    <GlobalStateContext.Provider
      value={{
        updatedAt,
        logo,
        setUpdatedAt,
        setLogo,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export { GlobalStateContext, GlobalStateContextProvider };
