import React from "react";
import PortableText from "@sanity/block-content-to-react";
import ColorPreview from "../ColorPreview/ColorPreview";
import { pageWrapper } from "./BlockContent.module.scss";
import Image from "../Image/Image";
import TextStyles from "../TextStyles/TextStyles";
import ColorCombinations from "../ColorCombinations/ColorCombinations";
import ImageAndTextGrid from "../ImageAndTextGrid/ImageAndTextGrid";
import FileUpload from "../FileUpload/FileUpload";
import VideoGrid from "../VideoGrid/VideoGrid";
import ImageGrid from "../ImageGrid/ImageGrid";

const serializers = {
  types: {
    image: Image,
    colors: ColorPreview,
    textStyles: TextStyles,
    colorCombinations: ColorCombinations,
    imageAndTextGrid: ImageAndTextGrid,
    fileUpload: FileUpload,
    videos: VideoGrid,
    imageGrid: ImageGrid,
    undefined: () => null,
  },
  marks: {},
};

const BlockContent = ({ blocks }) => {
  if (!blocks) return null;
  return (
    <PortableText
      blocks={blocks}
      serializers={serializers}
      className={pageWrapper}
    />
  );
};

export default BlockContent;
