import React from "react";
import { cn } from "../../lib/helpers";
import WCAGLabel from "../WCAGLabel/WCAGLabel";
import * as styles from "./ColorPreview.module.scss";
import {
  cleanHex,
  whiteHasHighestContrast,
  toCMYK,
  toHSL,
  toRGB,
  WcagContrast,
} from "./colorUtils";

const Color = ({ name, hex }) => {
  hex = cleanHex(hex);
  return (
    <div
      className={cn(
        styles.colorField,
        whiteHasHighestContrast(hex) ? styles.white : styles.black
      )}
    >
      <div className={styles.front}>
        <div style={{ backgroundColor: hex }} />
        <h4>{name}</h4>
        <p>{hex}</p>
      </div>
      <div className={styles.back}>
        <div>
          <h4>{name}</h4>
          <ul>
            <li>HEX: {hex}</li>
            <li>{toRGB(hex)}</li>
            <li>{toHSL(hex)}</li>
            <li>{toCMYK(hex)}</li>
          </ul>
        </div>
        <div>
          <div className={styles.contrast}>
            <div
              className={styles.colorCircle}
              style={{ backgroundColor: hex }}
            />
            <div
              className={styles.colorCircle}
              style={{ backgroundColor: "#000" }}
            />
            <span>{WcagContrast("#000000", hex)}:1</span>
            <WCAGLabel conrastRatio={WcagContrast("#000000", hex)} largeText />
          </div>
          <div className={styles.contrast}>
            <div
              className={styles.colorCircle}
              style={{ backgroundColor: hex }}
            />
            <div
              className={styles.colorCircle}
              style={{ backgroundColor: "#fff" }}
            />
            <span>{WcagContrast("#FFFFFF", hex)}:1</span>
            <WCAGLabel conrastRatio={WcagContrast("#FFFFFF", hex)} largeText />
          </div>
        </div>
      </div>
    </div>
  );
};

const ColorPreview = ({ node: { colors } }) => {
  return (
    <div className={styles.colorGrid}>
      {colors.map((color) => (
        <Color {...color} colors={colors} key={color.hex} />
      ))}
    </div>
  );
};

export default ColorPreview;
