import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { GlobalStateContextProvider } from "../context/globalContext";
import { Router } from "@reach/router";
import BlockContent from "../components/BlockContent/BlockContent";
import { graphql, useStaticQuery } from "gatsby";
import { slugify } from "slugify";
import SideNav from "../components/SideNav/SideNav";
import Nav from "../components/Nav/Nav";
import Footer from "../components/Footer/Footer";
import { wrapper } from "./Layout.module.scss";
import { innerWrapper } from "./Layout.module.scss";

const App = ({ children }) => {
  const {
    sanityBrandGuideline: { subPage, logo },
  } = useStaticQuery(BRAND_GUIDELINES);

  return (
    <GlobalStateContextProvider>
      <Nav logo={logo.asset.url} />
      <div className={wrapper}>
        <SideNav subPages={subPage} />
        {children}
      </div>
      <Footer />
    </GlobalStateContextProvider>
  );
};

const BRAND_GUIDELINES = graphql`
  query {
    sanityBrandGuideline(_id: { regex: "/brandGuideline$/" }) {
      id
      _updatedAt
      companyName
      logo {
        asset {
          url
        }
      }
      subPage {
        title
      }
    }
  }
`;

export default App;
