import React from "react";
import * as styles from "./WCAGLabel.module.scss";

const WCAGLabel = ({ conrastRatio, largeText }) => {
  let label = "Fail";
  const AAAThreshold = largeText ? 4.5 : 7;
  const AAThreshold = largeText ? 3 : 4.5;
  if (conrastRatio >= AAAThreshold) {
    label = "AAA";
  } else if (conrastRatio >= AAThreshold) {
    label = "AA";
  }

  return (
    <div
      className={styles.label}
      style={{
        backgroundColor:
          label === "Fail"
            ? "var(--color-gray-200)"
            : "var(--color-success-500)",
      }}
    >
      {label}
    </div>
  );
};

export default WCAGLabel;
