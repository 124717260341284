const _slugify = require("slugify");
_slugify.extend({ "&": "og" });

function cn(...args) {
  return args.filter(Boolean).join(" ");
}

const getImageResolution = (listLength) => {
  switch (listLength) {
    case 1:
      return 1400;
    case 2:
      return 800;
    case 3: {
      return 600;
    }
    default:
      return 800;
  }
};

const toDateString = (date) => {
  const leadingZero = (s) => {
    return ("0" + s).slice(-2);
  };
  date = new Date(date);
  return `${leadingZero(date.getDate())}.${leadingZero(
    date.getMonth() + 1
  )}.${date.getFullYear()} kl. ${leadingZero(date.getHours())}:${leadingZero(
    date.getMinutes()
  )}`;
};

const slugify = (string) => _slugify(string, { lower: true });
module.exports = {
  cn,
  getImageResolution,
  toDateString,
  slugify,
};
