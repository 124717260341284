import React from "react";
import Video from "../Video/Video";
import * as styles from "./VideoGrid.module.scss";

const VideoGrid = ({ node: { videos } }) => {
  return (
    <div
      className={styles.videoGrid}
      style={{ gridTemplateColumns: `repeat(${videos.length}, 1fr)` }}
    >
      {videos.map((video) => (
        <Video url={video.url} key={video.url} />
      ))}
    </div>
  );
};

export default VideoGrid;
