import React from "react";
import PropTypes from "prop-types";
import Vimeo from "@u-wave/react-vimeo";
const Video = ({ url }) => {
  let id;
  const parts = url.split("/");
  id = parts[parts.length - 1];
  console.log(url);
  return id ? (
    <Vimeo
      video={id}
      autoplay
      muted
      loop
      showPortrait={false}
      controls={false}
      showTitle={false}
      background={true}
      showByline={false}
      responsive={true}
    />
  ) : (
    ""
  );
  //   }
};

Video.propTypes = {
  node: PropTypes.shape({
    _type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

export default Video;
